

<app-header-style-two></app-header-style-two>

<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><a href="courses-2-columns-style-1.html">{{"detailC1.home" | translate}}</a></li>
                <li>{{"detailC1.title1" | translate}}</li>
            </ul>
            <h2>{{"detailC1.title1" | translate}}</h2>
        </div>
    </div>
</div>


<div class="courses-details-area pt-100 pb-70">
    <div class="container">
        <div class="courses-details-header">
            <div class="row align-items-center">
                <div class="col-lg-8">
                    <div class="courses-title">
                        <h2>{{"detailC1.title" | translate}}</h2>
                       <p>{{"detailC1.subtitle" | translate}}</p>
                    </div>

                    <div class="courses-meta">
                        <ul>



                        </ul>
                    </div>
                </div>

            </div>
        </div>
        <div class="row">
            <div class="col-lg-8">
                <div class="courses-details-image text-center">
                    <img src="assets/img/courses/C1.jpg" alt="image">
                </div>
                <div class="courses-details-desc">
                    <h3>{{"detailC1.learn1" | translate}}</h3>
                    <div class="why-you-learn">
                        <ul>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    {{"detailC1.learn2" | translate}}
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    {{"detailC1.learn3" | translate}}
                                </span>
                            </li>
                        </ul>
                    </div>
                    <h3>{{"detailC1.requir1" | translate}}</h3>
                    <div class="about-text">
                        <ul class="features-list">
                        <li><i class='bx bx-check'></i>{{"detailC1.requir2" | translate}}</li>
                        <li><i class='bx bx-check'></i>{{"detailC1.requir3" | translate}}</li>
                    </ul>
                    </div>
                    <h3>Description</h3>
                    <div class="about-text">
                        <ul class="features-list">
                    <li><i class='bx bx-check'></i>{{"detailC1.descritionp1" | translate}}</li>
                    <li><i class='bx bx-check'></i>{{"detailC1.descritionp2" | translate}}</li>
                    <li><i class='bx bx-check'></i>{{"detailC1.descritionp3" | translate}}</li>
                    <li><i class='bx bx-check'></i>{{"detailC1.descritionp4" | translate}}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="courses-sidebar-information">
                    <ul>

                        <li>
                            <span><i class='bx bx-time'></i> {{ "detailA1.Length" | translate }}</span>
                        C1==>140h/1200dt
                        </li>
                        <li>
                            <span><i class='bx bx-tachometer'></i> {{"detailC1.Effort" |translate}}</span>
                            2–5 {{"detailC1.Effort1" |translate}}
                        </li>
                        <li>
                            <span><i class='bx bxs-institution'></i> {{"detailC1.Institution" |translate}}</span>
                            <a href="https://www.google.com/maps/place/Lessing-Institut/@35.5146872,11.0384079,15z/data=!4m9!1m2!2m1!1sAvenue+02+Mars+1934+Mahdia+Immeuble+Snene+1+Etage+Mahdia,+5100,+Tunisie+!3m5!1s0x1302230565c237bb:0xeb4f214f259884cf!8m2!3d35.5071004!4d11.0526748!15sCkdBdmVudWUgMDIgTWFycyAxOTM0IE1haGRpYSBJbW1ldWJsZSBTbmVuZSAxIEV0YWdlIE1haGRpYSwgNTEwMCwgVHVuaXNpZZIBD2xhbmd1YWdlX3NjaG9vbA" target="_blank" class="d-inline-block">Lessing Institut {{"detailC1.online" |translate}}</a>                        </li>

                        <li>
                            <span><i class='bx bx-certification'></i>{{"detailC1.attestation" |translate}} </span>
                            {{"detailC1.yes" |translate}}
                        </li>
                    </ul>
                </div>
                <div class="courses-purchase-info">
                    <h4>{{"detailC1.interest" |translate}}</h4>
                    <p>{{"detailC1.interest2" |translate}}</p>
                    <a routerLink="/contact" class="d-inline-block">{{"detailC1.request" |translate}}</a>
                </div>
            </div>
        </div>
    </div>
</div>
