<footer class="footer-area">
    <div class="container">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Contact Us</h3>
                    <ul class="contact-us-link">
                        <li>
                            <i class='bx bx-map'></i>
                            <a href="https://www.google.com/maps/place/Lessing-Institut/@35.5146872,11.0384079,15z/data=!4m9!1m2!2m1!1sAvenue+02+Mars+1934+Mahdia+Immeuble+Snene+1+Etage+Mahdia,+5100,+Tunisie+!3m5!1s0x1302230565c237bb:0xeb4f214f259884cf!8m2!3d35.5071004!4d11.0526748!15sCkdBdmVudWUgMDIgTWFycyAxOTM0IE1haGRpYSBJbW1ldWJsZSBTbmVuZSAxIEV0YWdlIE1haGRpYSwgNTEwMCwgVHVuaXNpZZIBD2xhbmd1YWdlX3NjaG9vbA" target="_blank">Avenue 02 Mars 1934 Mahdia Immeuble Snen 1 Etage Mahdia, 5100, Tunisie</a>
                        </li>
                        <li>
                            <i class='bx bx-phone-call'></i>
                            <a href="tel:+11234567890">+216 26615614</a>
                        </li>
                        <li>
                            <i class='bx bx-envelope'></i>
                            <a href="mailto:info@lessing-institut.com">info@lessing-institut.com</a>
                        </li>
                    </ul>
                    <ul class="social-link">
                        <li><a href="https://www.facebook.com/Lessing-Institut-338115264045130" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="https://www.instagram.com/lesssing_institut/" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="https://www.linkedin.com/in/lessing-institut-tunisia-565084230/" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        <li><a href="https://www.youtube.com/channel/UCzrcyGSWuiDz-KAs3N2zGtQ" class="d-block" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>

    </div>
    <div class="footer-bottom-area">
        <div class="container">
            <div class="logo">
                <a routerLink="/http://www.apps4you.org/" class="d-inline-block"><img src="assets/img/black-logo.png" alt="image"></a>
            </div>
            <p><i class='bx bx-copyright'></i>2021 --- Site web Designed By <a href="http://www.apps4you.org/" target="_blank">Apps4you</a> | All rights reserved.</p>
        </div>
    </div>
</footer>

<ngx-scrolltop></ngx-scrolltop>
