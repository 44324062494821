
<div class="row">
    <div class="col-lg-3 col-md-3 col-6">
        <div class="single-funfact">
            <div class="icon">
                <i class='bx bx-calendar-event'></i>
            </div>
            <p> {{"funfacts.CreativeEvents" | translate}}</p>
        </div>
    </div>
    <div class="col-lg-3 col-md-3 col-6">
        <div class="single-funfact">
            <div class="icon">
                <i class='bx bx-book-reader'></i>
            </div>
            <p>{{"funfacts.Library" | translate}}</p>
        </div>
    </div>
    <div class="col-lg-3 col-md-3 col-6">
        <div class="single-funfact">
            <div class="icon">
                <i class='bx bx-cake'></i>
            </div>
            <p>{{"funfacts.equipement" | translate}}</p>
        </div>
    </div>
    <div class="col-lg-3 col-md-3 col-6">
        <div class="single-funfact">
            <div class="icon">
                <i class='bx bx-wifi'></i>
            </div>
            <p>{{"funfacts.wifi" | translate}} </p>
        </div>
    </div>
</div>
