<div class="home-slides">
    <owl-carousel-o [options]="homeSlides">
        <ng-template carouselSlide>
            <div class="main-banner item-bg1">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="main-banner-content">

                                <h1>{{ "HOMETWOMAINBANNER.title1" | translate }}</h1>
                                <div class="btn-box">
                                    <a routerLink="/courses-category-style-1" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">{{ "HOMETWOMAINBANNER.view" | translate }}</span><i class="bx bx-move-horizontal icon-arrow after"></i></a>
                                    <a routerLink="/contact" class="optional-btn">{{ "HOMETWOMAINBANNER.contact" | translate }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="main-banner item-bg2">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="main-banner-content">
                                <span class="sub-title">  Frank Smith </span>

                                <h1>{{ "HOMETWOMAINBANNER.saying" | translate }}</h1>
                                <div class="btn-box">
                                    <a routerLink="/courses-category-style-1" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">{{ "HOMETWOMAINBANNER.view" | translate }}</span><i class="bx bx-move-horizontal icon-arrow after"></i></a>
                                    <a routerLink="/contact" class="optional-btn">{{ "HOMETWOMAINBANNER.contact" | translate }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="main-banner item-bg3">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="main-banner-content text-center">
                                <h1>{{ "HOMETWOMAINBANNER.title2" | translate }}</h1>
                                <div class="btn-box">
                                    <a routerLink="/courses-category-style-1" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">{{ "HOMETWOMAINBANNER.view" | translate }}</span><i class="bx bx-move-horizontal icon-arrow after"></i></a>
                                    <a routerLink="/contact" class="optional-btn">{{ "HOMETWOMAINBANNER.contact" | translate }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </owl-carousel-o>
</div>
