<header class="header-area">
    <div class="top-header">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-8">
                    <ul class="top-header-contact-info">
                        <li>
                            <i class='bx bx-phone-call'></i>
                            <span>{{ "headerStyle.support" | translate }}</span>
                            <a href="tel:502464674">+216 26615614</a>
                        </li>
                        <li>
                            <i class='bx bx-map'></i>
                            <span> {{ "headerStyle.location" | translate }}</span>
                            <p><a href="https://www.google.com/maps/place/Lessing-Institut/@35.5146872,11.0384079,15z/data=!4m9!1m2!2m1!1sAvenue+02+Mars+1934+Mahdia+Immeuble+Snene+1+Etage+Mahdia,+5100,+Tunisie+!3m5!1s0x1302230565c237bb:0xeb4f214f259884cf!8m2!3d35.5071004!4d11.0526748!15sCkdBdmVudWUgMDIgTWFycyAxOTM0IE1haGRpYSBJbW1ldWJsZSBTbmVuZSAxIEV0YWdlIE1haGRpYSwgNTEwMCwgVHVuaXNpZZIBD2xhbmd1YWdlX3NjaG9vbA" target="_blank">Mahdia</a></p>
                        </li>
                        <li>
                            <i class='bx bx-envelope'></i>
                            <span>{{ "headerStyle.email" | translate }}</span>
                            <a href="mailto:info@lessing-institut.com">info@lessing-institut.com</a>
                        </li>
                    </ul>
                </div>


            </div>
        </div>
    </div>
    <div class="navbar-area" ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
                <a class="navbar-brand" routerLink="/course-portal-online"><img src="assets/img/black-logo.png" alt="logo"></a>
                <button class="navbar-toggler" type="button" (click)="toggleClass()">
                    <span class="burger-menu">
                        <span class="top-bar"></span>
                        <span class="middle-bar"></span>
                        <span class="bottom-bar"></span>
                    </span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav">

                        <li class="nav-item"><a routerLink="/course-portal-online" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{ "headerStyle.Home" | translate }}</a></li>
                        <li class="nav-item"><a routerLink="/about-style-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{ "headerStyle.About us" | translate }}</a></li>









                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link">{{ "headerStyle.Courses" | translate }} <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/courses-category-style-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{ "headerStyle.coursesdetail" | translate }}</a></li>



                                <li class="nav-item"><a href="javascript:void(0)" class="nav-link">{{ "headerStyle.Courseslist" | translate }} <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/single-courses" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"> Formation A1</a></li>
                                        <li class="nav-item"><a routerLink="/single-courses2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"> Formation A2</a></li>
                                        <li class="nav-item"><a routerLink="/single-courses3" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"> Formation B1</a></li>
                                        <li class="nav-item"><a routerLink="/single-courses4" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"> Formation B2</a></li>
                                        <li class="nav-item"><a routerLink="/single-courses5"  class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"> Formation C1</a></li>

                                    </ul>
                                </li>


                            </ul>
                        </li>









                        <li class="nav-item"><a routerLink="/events" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{ "headerStyle.Events" | translate }}</a></li>
                        <li class="nav-item"><a routerLink="/gallery" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{ "headerStyle.Gallery" | translate }}</a></li>


                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{ "headerStyle.Contact" | translate }}</a></li>
                    </ul>
                    <div class="others-option">
                        <div class="dropdown language-switcher d-inline-block" [class.active]="classApplied2">
                            <button class="dropdown-toggle" type="button" (click)="toggleClass2()">

                                <span> {{ "headerStyle.Lang" | translate }} <i class='bx bx-chevron-down'></i></span>
                            </button>
                            <div class="dropdown-menu">

                                <a href="#" class="dropdown-item d-flex align-items-center">
                                    <img src="assets/img/france-flag.jpg" class="shadow-sm" alt="flag">
                                    <a (click)="translate.use('fr')" class="fr myClickableThingy">French</a>
                                </a>
                                <a href="#" class="dropdown-item d-flex align-items-center">
                                    <img src="assets/img/us-flag.jpg" class="shadow" alt="image">
                                    <a (click)="translate.use('en')" class="en myClickableThingy">English</a>
                                </a>

                            </div>
                        </div>

                    </div>

                </div>


            </nav>
        </div>
    </div>
</header>
