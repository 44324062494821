import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-courses-details-page',
  templateUrl: './courses-details-page.component2.html',
  styleUrls: ['./courses-details-page.component2.scss']
})
// tslint:disable-next-line:component-class-suffix
export class CoursesDetailsPageComponentDeux implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
