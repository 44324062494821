<app-header-style-two></app-header-style-two>

<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/course-portal-online">{{ "detailA1.Home" | translate }}</a></li>
                <li><a routerLink="/courses-category-style-1">{{ "detailA1.Courses" | translate }}</a></li>
                <li>{{ "detailA2.title1" | translate }}</li>
            </ul>
            <h2>{{ "detailA2.title1" | translate }}</h2>
        </div>
    </div>
</div>


<div class="courses-details-area pt-100 pb-70">
    <div class="container">
        <div class="courses-details-header">
            <div class="row align-items-center">
                <div class="col-lg-8">
                    <div class="courses-title">
                        <h2>{{ "detailA2.title" | translate }}</h2>
                        <p>{{ "detailA2.subtitle" | translate }}</p>
                    </div>
                    <div class="courses-meta">
                        <ul>



                        </ul>
                    </div>
                </div>

            </div>
        </div>
        <div class="row">
            <div class="col-lg-8">
                <div class="courses-details-image text-center">
                    <img src="assets/img/courses/A2.jpg" alt="image">
                </div>
                <div class="courses-details-desc">
                    <h3>{{ "detailA2.learn" | translate }}</h3>
                    <div class="why-you-learn">
                        <ul>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    {{ "detailA2.learn1" | translate }}
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    {{ "detailA2.learn2" | translate }}

                                </span>
                            </li>

                        </ul>
                    </div>
                    <h3> {{ "detailA2.requirment" | translate }}</h3>
                    <div class="about-text">
                        <ul class="features-list">
                        <li><i class='bx bx-check'></i> {{ "detailA2.requirment1" | translate }}</li>
                    </ul>
                    </div>
                    <h3> {{ "detailA2.desc" | translate }}</h3>
                    <div class="about-text">
                        <ul class="features-list">
                    <li><i class='bx bx-check'></i>{{ "detailA2.desc1" | translate }}</li>
                    <li><i class='bx bx-check'></i>{{ "detailA2.desc2" | translate }}</li>
                    <li><i class='bx bx-check'></i>{{ "detailA2.desc3" | translate }}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="courses-sidebar-information">
                    <ul>

                        <li>
                            <span><i class='bx bx-time'></i> {{ "detailA2.Length" | translate }}</span>
                            A2.1+A2.2==>80h/680dt
                        </li>
                        <li>
                            <span><i class='bx bx-tachometer'></i> {{ "detailA2.Effort" | translate }}</span>
                            9–12  {{ "detailA2.Effort1" | translate }}
                        </li>
                        <li>
                            <span><i class='bx bxs-institution'></i> {{ "detailA2.Institution" | translate }}</span>
                            <a href="https://www.google.com/maps/place/Lessing-Institut/@35.5146872,11.0384079,15z/data=!4m9!1m2!2m1!1sAvenue+02+Mars+1934+Mahdia+Immeuble+Snene+1+Etage+Mahdia,+5100,+Tunisie+!3m5!1s0x1302230565c237bb:0xeb4f214f259884cf!8m2!3d35.5071004!4d11.0526748!15sCkdBdmVudWUgMDIgTWFycyAxOTM0IE1haGRpYSBJbW1ldWJsZSBTbmVuZSAxIEV0YWdlIE1haGRpYSwgNTEwMCwgVHVuaXNpZZIBD2xhbmd1YWdlX3NjaG9vbA" target="_blank" class="d-inline-block">Lessing Institut  {{ "detailA2.online" | translate }}</a>
                        </li>

                        <li>
                            <span><i class='bx bx-certification'></i> {{ "detailA2.attestation" | translate }}</span>
                            {{ "detailA2.yes" | translate }}
                        </li>
                    </ul>
                </div>
                <div class="courses-purchase-info">
                    <h4>{{ "detailA2.interest" | translate }}</h4>
                    <p>{{ "detailA2.interest2" | translate }}</p>
                    <a routerLink="/contact" class="d-inline-block">  {{ "detailA2.request" | translate }}  </a>
                </div>
            </div>
        </div>
    </div>
</div>
