<app-header-style-two></app-header-style-two>

<div class="page-title-area item-bg3">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/course-portal-online">{{ "Gallery.home" | translate }}</a></li>
                <li>{{ "Gallery.Gallery" | translate }}</li>
            </ul>
            <h2>{{ "Gallery.Gallery" | translate }}</h2>
        </div>
    </div>
</div>

<div class="gallery-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let image of _album; let i=index">
                <div class="single-gallery-item mb-30">
                    <img [src]="image.thumb" (click)="open(i)" />
                </div>
            </div>
        </div>
    </div>
</div>
