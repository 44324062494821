import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateCacheService } from 'ngx-translate-cache';
@Component({
    selector: 'app-header-style-two',
    templateUrl: './header-style-two.component.html',
    styleUrls: ['./header-style-two.component.scss']
})
export class HeaderStyleTwoComponent implements OnInit {

    constructor(public translate: TranslateService,
                translateCacheService: TranslateCacheService) {
        translate.addLangs(['en', 'fr', 'de']);
        const browserLang =
            translateCacheService.getCachedLanguage() || translate.getBrowserLang();
        translate.use(browserLang.match(/en|fr|de/) ? browserLang : 'en');

        translateCacheService.init();
    }

    classApplied = false;

    classApplied2 = false;

    classApplied3 = false;

    ngOnInit(): void {
    }
    toggleClass() {
        this.classApplied = !this.classApplied;
    }
    toggleClass2() {
        this.classApplied2 = !this.classApplied2;
    }
    toggleClass3() {
        this.classApplied3 = !this.classApplied3;
    }

}
