<section class="courses-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-start">
            <span class="sub-title">{{ "homecourse.type" | translate }}</span>
            <h2>{{ "homecourse.type2" | translate }}</h2>
            <a routerLink="/courses-category-style-1" class="default-btn"><i class='bx bx-show-alt icon-arrow before'></i><span class="label">{{"homecourse.Allcourses" | translate}}</span><i class="bx bx-show-alt icon-arrow after"></i></a>
        </div>
        <ngx-tabset>



                <div class="row">


                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/courses-category-style-1" class="d-block"><img src="assets/img/courses/courses4.jpg" alt="image"></a>

                            </div>
                            <div class="courses-content">

                                <h5 align="center"><a routerLink="/courses-category-style-1" class="d-inline-block">{{ "homecourse.groupe" | translate }}</a></h5>
                            </div>

                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/courses-category-style-1" class="d-block"><img src="assets/img/courses/courses1.jpg" alt="image"></a>

                            </div>
                            <div class="courses-content">

                                <h5 align="center"><a routerLink="/courses-category-style-1" class="d-inline-block">{{ "homecourse.private" | translate }}</a></h5>

                            </div>

                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/scourses-category-style-1" class="d-block"><img src="assets/img/courses/courses3.jpg" alt="image"></a>
                                <div class="courses-tag">
                                </div>
                            </div>
                            <div class="courses-content">

                                <h5 align="center"><a routerLink="/courses-category-style-1" class="d-inline-block">{{ "homecourse.online" | translate }}</a></h5>

                            </div>

                        </div>
                    </div>
                </div>




        </ngx-tabset>
    </div>
</section>
