<app-header-style-two></app-header-style-two>

<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Partner</li>
            </ul>
            <h2>Partner</h2>
        </div>
    </div>
</div>

<div class="partner-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our Company & Partners</h2>
        </div>
        <app-partner-style-two></app-partner-style-two>
    </div>
</div>

<div class="partner-area bg-color ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our Company & Partners</h2>
        </div>
        <app-partner-style-one></app-partner-style-one>
    </div>
</div>

<div class="feedback-area ptb-100">
    <div class="container">
        <app-feedback-style-one></app-feedback-style-one>
    </div>
</div>
<div class="partner-slides">
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a href="#" target="_blank" class="d-block">
                    <img src="assets/img/partner/partner1.png" alt="image">
                </a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a href="#" target="_blank" class="d-block">
                    <img src="assets/img/partner/partner2.png" alt="image">
                </a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a href="#" target="_blank" class="d-block">
                    <img src="assets/img/partner/partner3.png" alt="image">
                </a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a href="#" target="_blank" class="d-block">
                    <img src="assets/img/partner/partner4.png" alt="image">
                </a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a href="#" target="_blank" class="d-block">
                    <img src="assets/img/partner/partner5.png" alt="image">
                </a>
            </div>
        </ng-template>

</div>
