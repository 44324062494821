<app-header-style-two></app-header-style-two>

<div class="page-title-area item-bg4">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/course-portal-online">{{ "Events.Home" | translate }}</a></li>
                <li>{{ "Events.Events" | translate }}</li>
            </ul>
            <h2>{{ "Events.Events" | translate }}</h2>
        </div>
    </div>
</div>

<div class="events-area pt-100 pb-70">
    <div class="container">


        <div class="single-events-box mb-30">
            <div class="events-box">
                <div class="events-image">
                    <div class="image bg3">
                        <img src="assets/img/events/events3.jpg" alt="image">
                    </div>
                </div>
                <div class="events-content">
                    <div class="content">
                        <h3><a routerLink="/single-events">يوم مفتوح للاجابة على جميع استفسراتكم في ما يخص الهجرة إلى ألمانيا</a></h3>
                        <p>الهجرة إلى ألمانيا</p>
                        <span class="location"><i class='bx bx-map'></i> Lessing-Institut - Avenue du 2 Mars 1934, المهدية, تونس</span>
                        <a routerLink="/contact" class="join-now-btn">{{ "Events.Join" | translate }}</a>
                    </div>
                </div>
                <div class="events-date">
                    <div class="date">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <span>01</span>
                                <h3>{{ "Events.December" | translate }}</h3>
                                <p>11:30 PM</p>
                                <i class='bx bx-calendar'></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="single-events-box mb-30">
            <div class="events-box">
                <div class="events-image">
                    <div class="image bg4">
                        <img src="assets/img/events/events4.jpg" alt="image">
                    </div>
                </div>
                <div class="events-content">
                    <div class="content">
                        <h3><a routerLink="/single-events">{{ "Events.Opening" | translate }}</a></h3>
                        <span class="location"><i class='bx bx-map'></i> Lessing-Institut - Avenue du 2 Mars 1934, المهدية, تونس </span>
                        <a routerLink="/contact" class="join-now-btn">{{ "Events.Join" | translate }}</a>
                    </div>
                </div>
                <div class="events-date">
                    <div class="date">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <span>02</span>
                                <h3>{{ "Events.february" | translate }}</h3>
                                <p>10:30 PM</p>
                                <i class='bx bx-calendar'></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
