<div class="feedback-slides">
    <owl-carousel-o [options]="feedbackSlides">

        <ng-template carouselSlide>
            <div class="single-feedback-item">
                <p>{{ "feedback.discrep" | translate }} </p>
                <div class="info">
                    <h3> Hatem Monser</h3>
                    <span>{{ "feedback.role2" | translate }}</span>
                    <span>Tel.:+49 15259635500 </span>
                    <span><a href="mailto:h.monser@lessing-institut.com" class="bx bx-envelope" ><a> mail</a></a></span>
                    <img src="assets/img/instructor/dirc.jpg" class="shadow rounded-circle" alt="image">
                </div>
            </div>
        </ng-template>

    </owl-carousel-o>
</div>
