<app-header-style-two></app-header-style-two>

<app-hometwo-main-banner></app-hometwo-main-banner>


<app-our-mission></app-our-mission>


<div class="courses-categories-area bg-image pt-100 pb-70">
    <div class="container">
        <div class="section-title text-start">
            <span class="sub-title">{{ "hometwo.categorie" | translate }}</span>
            <h2>{{ "hometwo.course" | translate }}</h2>
            <a routerLink="/courses-category-style-1" class="default-btn"><i class='bx bx-show-alt icon-arrow before'></i><span class="label">{{"hometwo.view" | translate}}</span><i class="bx bx-show-alt icon-arrow after"></i></a>
        </div>
        <app-categories-style-one></app-categories-style-one>
    </div>
</div>






<div class="funfacts-area pt-100">
    <div class="container">
        <div class="section-title text-start">
            <span class="sub-title">{{"hometwo.Equipped" | translate}}</span>
            <h2>{{"hometwo.speed" | translate}}</h2>
        </div>

        <div class="funfacts-inner">

            <app-funfacts></app-funfacts>
        </div>
    </div>
</div>

<app-hometwo-courses></app-hometwo-courses>



    <div class="container">
        <div class="section-title">
            <h2>{{"hometwo.company" |translate}}</h2>
        </div>
        <app-partner-style-one></app-partner-style-one>
    </div>






<div class="courses-categories-area bg-image pt-100 pb-70">
    <div class="container">
        <div class="section-title text-start">
        </div>
    </div>
</div>


<app-students-feedback-form></app-students-feedback-form>





