<app-header-style-two></app-header-style-two>

<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/course-portal-online">{{ "ABOUT.Homeabout" | translate }}</a></li>
                <li>{{ "ABOUT.Title" | translate }}</li>
            </ul>
            <h2> {{ "ABOUT.bigtitle1" | translate }}</h2>
            <h2> {{ "ABOUT.bigtitle2" | translate }}</h2>
        </div>
    </div>
</div>
<div class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about/Lessing.jpg" class="shadow" alt="image">
                    <img src="assets/img/about/about2.png" class="shadow" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">{{ "ABOUT.Title" | translate }} </span>
                    <h2>{{ "ABOUT.subtitle" | translate }}</h2>
                    <h6>{{ "ABOUT.Prg5" | translate }}</h6>
                    <p>{{ "ABOUT.Prg1" | translate }}</p>
                    <p>{{ "ABOUT.Prg2" | translate }}</p>
                    <p> {{ "ABOUT.Prg3" | translate }} </p>
                    <p>{{ "ABOUT.Prg4" | translate }}</p>
                    <div class="features-text">
                        <h5><i class='bx bx-planet'></i>{{ "ABOUT.Innovation" | translate }}</h5>
                        <p>{{ "ABOUT.local" | translate }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="about-inner-area">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>{{ "ABOUT.advan" | translate }}</h3>
                        <ul class="features-list">
                            <li><i class='bx bx-check'></i> {{ "ABOUT.advan1" | translate }}</li>
                            <li><i class='bx bx-check'></i> {{ "ABOUT.advan2" | translate }}</li>
                            <li><i class='bx bx-check'></i> {{ "ABOUT.advan3" | translate }}</li>
                            <li><i class='bx bx-check'></i> {{ "ABOUT.advan41" | translate }}</li>

                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>{{ "ABOUT.super" | translate }}</h3>
                        <ul class="features-list">
                            <li><i class='bx bx-check'></i> {{ "ABOUT.super1" | translate }}</li>
                            <li><i class='bx bx-check'></i> {{ "ABOUT.super2" | translate }}</li>
                            <li><i class='bx bx-check'></i> {{ "ABOUT.super3" | translate }}</li>
                            <li><i class='bx bx-check'></i> {{ "ABOUT.super4" | translate }}</li>
                            <li><i class='bx bx-check'></i> {{ "ABOUT.super5" | translate }}</li>

                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                    <div class="about-text">
                        <h3>{{ "ABOUT.pedag" | translate }}</h3>
                        <ul class="features-list">
                            <li><i class='bx bx-check'></i> {{ "ABOUT.pedag1" | translate }}</li>
                            <li><i class='bx bx-check'></i> {{ "ABOUT.pedag2" | translate }}</li>
                            <li><i class='bx bx-check'></i> {{ "ABOUT.pedag3" | translate }}</li>
                            <li><i class='bx bx-check'></i> {{ "ABOUT.pedag4" | translate }}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

    </div>

</div>

