<div class="students-feedback-form-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="students-feedback-form-content">
                    <h2> {{ "feedback.titre" | translate }}</h2>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="students-feedback-form">
                    <h3> {{ "feedback.form" | translate }}</h3>
                    <form >
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder = " {{ 'feedback.name'| translate }}">
                                    <span class="label-title"><i class='bx bx-user'></i></span>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder=" {{ 'feedback.adress'| translate }}">
                                    <span class="label-title"><i class='bx bx-home'></i></span>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder=" {{ 'feedback.email'| translate }}">
                                    <span class="label-title"><i class='bx bx-envelope'></i></span>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder=" {{ 'feedback.phone'| translate }}">
                                    <span class="label-title"><i class='bx bx-phone'></i></span>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea cols="30" rows="5" class="form-control" placeholder=" {{ 'feedback.write'| translate }}" ></textarea>
                                    <span class="label-title"><i class='bx bx-edit'></i></span>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn"><i class='bx bx-paper-plane icon-arrow before'></i><span class="label"> {{ 'feedback.message'| translate }} </span><i class="bx bx-paper-plane icon-arrow after"></i></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
