<app-header-style-two></app-header-style-two>

<div class="page-title-area item-bg2">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/course-portal-online">{{ "Contact.home" | translate }}</a></li>
                <li>{{ "Contact.cont" | translate }}</li>
            </ul>
            <h2>{{ "Contact.contact" | translate }}</h2>
        </div>
    </div>
</div>

<div class="contact-info-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-envelope'></i>
                    </div>
                    <h3>{{ "Contact.email" | translate }}</h3>
                    <p><a href="mailto:info@lessing-institut.com">info@lessing-institut.com</a></p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-map'></i>
                    </div>
                    <h3>{{ "Contact.location" | translate }}</h3>
                    <p><a href="https://www.google.com/maps/place/Lessing-Institut/@35.5146872,11.0384079,15z/data=!4m9!1m2!2m1!1sAvenue+02+Mars+1934+Mahdia+Immeuble+Snene+1+Etage+Mahdia,+5100,+Tunisie+!3m5!1s0x1302230565c237bb:0xeb4f214f259884cf!8m2!3d35.5071004!4d11.0526748!15sCkdBdmVudWUgMDIgTWFycyAxOTM0IE1haGRpYSBJbW1ldWJsZSBTbmVuZSAxIEV0YWdlIE1haGRpYSwgNTEwMCwgVHVuaXNpZZIBD2xhbmd1YWdlX3NjaG9vbA" target="_blank">Avenue 02 Mars 1934 Mahdia Immeuble Snen 1 Etage Mahdia, 5100, Tunisie</a></p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-phone-call'></i>
                    </div>
                    <h3>{{ "Contact.call" | translate }}</h3>
                    <p><a href="tel:1234567890">+216 26615614
 </a></p>
                    <p><a href="tel:2414524526">+49 15734018042</a></p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="contact-area pb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">{{ "Contact.contact" | translate }}</span>
            <h3>{{ "Contact.message" | translate }}</h3>
            <p> </p>
        </div>
        <div class="contact-form">
            <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name"  placeholder = " {{ 'feedback.name'| translate }}">
                            <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                <div *ngIf="name.errors.required">Name is required.</div>
                                <div *ngIf="name.errors.minlength">Name is minimum {{ name.errors.minlength.requiredLength }} character.</div>
                                <div *ngIf="name.errors.maxlength">Name is maximum 50 character.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email" placeholder=" {{ 'feedback.adress'| translate }}">
                            <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Email Address is required.</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required ngModel name="number" type="text" #number="ngModel" class="form-control" id="number"  placeholder=" {{ 'feedback.phone'| translate }}">
                            <div class="alert alert-danger" *ngIf="number.touched && !number.valid">Phone number is required.</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required ngModel name="subject" type="text" #subject="ngModel" class="form-control" id="subject" placeholder=" {{ 'feedback.subject'| translate }}">
                            <div class="alert alert-danger" *ngIf="subject.touched && !subject.valid">Phone subject is required.</div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="5" class="form-control"  placeholder=" {{ 'feedback.Yourmessage'| translate }}"></textarea>
                            <div class="alert alert-danger" *ngIf="message.touched && !name.valid">Message is required.</div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <button type="submit" class="default-btn" [class.disabled]="!contactForm.valid"><i class='bx bx-paper-plane icon-arrow before'></i><span class="label"> {{ 'feedback.message'| translate }}</span><i class="bx bx-paper-plane icon-arrow after"></i></button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="contact-bg-image"><img src="assets/img/map.png" alt="image"></div>
</div>


<div id="map">
    <iframe src="https://maps.google.com/maps?q=Lessing-Institut%20Avenue%2002%20Mars%201934%20Mahdia%20Immeuble%20Snen%201%20Etage%20Mahdia,%205100,%20Tunisie&t=&z=13&ie=UTF8&iwloc=&output=embed"></iframe>
</div>
