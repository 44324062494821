<div class="row">


    <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="single-instructor-member mb-30">
            <div class="member-image">
                <img src="assets/img/instructor/dirc.jpg" alt="images">
            </div>
            <div class="member-content">
                <h3><a routerLink="/single-instructor">	Hatem Monser </a></h3>
                <span>Directeur</span>
                <span>Tel.:+49 15259635500 </span>

                <ul class="social">
                    <li><a href="mailto:h.monser@lessing-institut.com" class="bx bx-envelope" target="_blank"><i class='bx bx-envelope'></i></a></li>
                    <li><a href="https://www.linkedin.com/in/hatem-monser-5732aa39/" class="linkedin" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    <li><a href="https://www.facebook.com/Lessing-Institut-338115264045130" class="facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                    <li><a href="https://www.instagram.com/lesssing_institut/" class="instagram" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                </ul>
            </div>
        </div>
    </div>


</div>
