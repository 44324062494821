<div class="partner-slides">
    <owl-carousel-o [options]="partnerSlides">
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a href="#" target="_blank" class="d-block">
                    <img src="assets/img/partner/jalel.jpg" alt="image">
                </a>
            </div>
        </ng-template>

        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a href="#" target="_blank" class="d-block">
                    <img src="assets/img/partner/Capture2.PNG" alt="image">
                </a>
            </div>
        </ng-template>

        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a href="#" target="_blank" class="d-block">
                    <img src="assets/img/partner/partner4%23.jpg" alt="image">
                </a>
            </div>
        </ng-template>
    </owl-carousel-o>
</div>
