<div class="testimonials-slides">
    <owl-carousel-o [options]="testimonialsSlides">

        <ng-template carouselSlide>
            <div class="single-testimonials-item">
                <div class="info">
                    <span>{{ "feedback.role2" | translate }}</span>
                    <span>Tel.:+49 15259635500 </span>
                    <span><a href="mailto:h.monser@lessing-institut.com" class="bx bx-envelope" ><a> mail</a></a></span>


                </div>
            </div>
        </ng-template>

    </owl-carousel-o>

    <div class="home-slides">
            <ng-template carouselSlide>
                <div class="main-banner item-bg1">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="main-banner-content">
                                    <span class="sub-title">Weapon is Education</span>
                                    <h1>Think out of the box and create a learning learner</h1>
                                    <p>Ednuv supports students by introducing collaborators outside R, internships and research experience abroad.</p>
                                    <div class="btn-box">
                                        <a routerLink="/courses-2-columns-style-1" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">View Courses</span><i class="bx bx-move-horizontal icon-arrow after"></i></a>
                                        <a routerLink="/contact" class="optional-btn">Get Started Free</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="main-banner item-bg2">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="main-banner-content">
                                    <span class="sub-title">The Future</span>
                                    <h1>Transformative Courses for those who learn differently</h1>
                                    <p>Ednuv supports students by introducing collaborators outside R, internships and research experience abroad.</p>
                                    <div class="btn-box">
                                        <a routerLink="/courses-2-columns-style-2" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">View Courses</span><i class="bx bx-move-horizontal icon-arrow after"></i></a>
                                        <a routerLink="/contact" class="optional-btn">Get Started Free</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="main-banner item-bg3">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="main-banner-content text-center">
                                    <span class="sub-title">Aim for Excellence</span>
                                    <h1>Learn a new skill from online courses</h1>
                                    <p>Ednuv supports students by introducing collaborators outside R, internships and research experience abroad.</p>
                                    <div class="btn-box">
                                        <a routerLink="/courses-2-columns-style-3" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">View Courses</span><i class="bx bx-move-horizontal icon-arrow after"></i></a>
                                        <a routerLink="/contact" class="optional-btn">Get Started Free</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
    </div>
</div>
