<app-header-style-two></app-header-style-two>
<div class="page-title-area item-bg2">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/course-portal-online">{{ "category.Home" | translate }}</a></li>
                <li>{{ "category.Title" | translate }}</li>
            </ul>
            <h2>{{ "category.CategoryList" | translate }} </h2>
        </div>
    </div>
</div>

<div class="courses-categories-area pt-200 pb-70">
    <div class="container">
        <div class="about-inner-area">
            <div class="row">

                    <div class="about-text">
                        <h3>{{ "category.inten" | translate }}</h3>
                        <p>{{ "category.part1" | translate }}</p>
                        <p>{{ "category.part2" | translate }}</p>
                        <p>{{ "category.part3" | translate }}</p>
                        <h6>{{ "category.part4" | translate }}</h6>

                        <ul class="features-list">
                            <div class="row">
                                <li><i class='bx bx-check'></i>{{ "category.trai2" | translate }}</li>
                                <li><i class='bx bx-check'></i> {{ "category.part6" | translate }}</li>
                                <li><i class='bx bx-check'></i>{{ "category.part8" | translate }}</li>

                                <li><i class='bx bx-check'></i> {{ "category.part5" | translate }}</li>
                            <li><i class='bx bx-check'></i>{{ "category.part9" | translate }}</li>
                                    <li><i class='bx bx-check'></i>{{ "category.trai" | translate }}




                            </div>


                        </ul>

                    </div>


        </div>
            <div class="about-inner-area">
                <div class="row">

                    <div class="about-text">
                        <h3>{{ "category.d10" | translate }}</h3>
                        <p>{{ "category.d11" | translate }}</p>
                        <ul class="features-list">
                                <div class="column middle" >
                                </div>
                        </ul>
                    </div>
                    <div class="courses-categories-slides">
                        <owl-carousel-o [options]="categoriesSlides">
                            <ng-template carouselSlide>
                                <div class="single-categories-courses-box mb-30">
                                    <div class="icon">
                                        <i class='bx bx-book-reader'routerLink="/single-courses" ></i>

                                    </div>
                                    <h6><a routerLink="/single-courses" >{{ "category.levela1" | translate }}</a></h6>
                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="single-categories-courses-box mb-30">
                                    <div class="icon">
                                        <i class='bx bx-book-open' routerLink="/single-courses2" ></i>
                                    </div>
                                    <h6><a routerLink="/single-courses2" >{{ "category.levela2" | translate }}</a></h6>
                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="single-categories-courses-box mb-30">
                                    <div class="icon">
                                        <i class='bx bx-book'></i>
                                    </div>
                                    <h6><a routerLink="/single-courses3" >{{ "category.levelb1" | translate }}</a></h6>

                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="single-categories-courses-box mb-30">
                                    <div class="icon">
                                        <i class='bx bxs-book-reader'></i>
                                    </div>
                                    <h6><a routerLink="/single-courses4" >{{ "category.levelb2" | translate }}</a></h6>


                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="single-categories-courses-box mb-30">
                                    <div class="icon">
                                        <i class='bx bx-laptop'></i>
                                    </div>
                                    <h6><a routerLink="/single-courses5" >{{"category.levelc1" | translate }}</a></h6>
                                </div>
                            </ng-template>
                        </owl-carousel-o>
                    </div>
                </div>

            </div>

            <div class="about-inner-area">
                <div class="row">

                    <div class="about-text">
                        <h3>{{"category.d1" | translate }}</h3>
                        <p>{{"category.d2" | translate }}</p>
                        <p>{{"category.d3" | translate }}</p>


                        <ul class="features-list">
                                <li><i class='bx bx-check'></i>{{"category.d5" | translate }}</li>
                                <li><i class='bx bx-check'></i> {{"category.d4" | translate }}</li>
                        </ul>
                    </div>
                </div>

            </div>
            <div class="about-inner-area">
                <div class="row">

                    <div class="about-text">


                        <h3>{{"category.d6" | translate }}</h3>
                        <p>{{"category.d7" | translate }}</p>
                        <p>{{"category.d8" | translate }}</p>
                        <p>{{"category.d9" | translate }}</p>


                    </div>
                </div>

            </div>

    </div>
</div>

</div>
