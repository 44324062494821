import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateCacheService } from 'ngx-translate-cache';
import {OwlOptions} from 'ngx-owl-carousel-o';
@Component({
  selector: 'app-category-page-one',
  templateUrl: './category-page-one.component.html',
  styleUrls: ['./category-page-one.component.scss']
})
export class CategoryPageOneComponent implements OnInit {

    constructor(public translate: TranslateService,
                translateCacheService: TranslateCacheService) {
        translate.addLangs(['en', 'fr', 'de']);
        const browserLang =
            translateCacheService.getCachedLanguage() || translate.getBrowserLang();
        translate.use(browserLang.match(/en|fr|de/) ? browserLang : 'en');
        translateCacheService.init();
    }

    ngOnInit(): void {
    }

    categoriesSlides: OwlOptions = {
        loop: true,
        nav: true,
        dots: false,
        autoplayHoverPause: true,
        autoplay: true,
        margin: 30,
        navText: [
            "<i class='bx bx-left-arrow-alt'></i>",
            "<i class='bx bx-right-arrow-alt'></i>"
        ],
        responsive: {
            0: {
                items: 1
            },
            576: {
                items: 2
            },
            768: {
                items: 3
            },
            1200: {
                items: 4
            }
        }
    };

}
