<div class="courses-categories-slides">
    <owl-carousel-o [options]="categoriesSlides">


        <ng-template carouselSlide>
            <div class="single-categories-courses-box mb-30">
                <div class="icon">
                    <i class='bx bx-book-open'></i>
                </div>
                <h3>A1</h3>
                <span>{{ "category.timeA" | translate }}</span>
                <a routerLink="/single-courses" class="link-btn"></a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-categories-courses-box mb-30">
                <div class="icon">
                    <i class='bx bx-book-reader'></i>
                </div>
                <h3>A2</h3>
                <span>{{ "category.timeA" | translate }}</span>
                <a routerLink="/single-courses2" class="link-btn"></a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-categories-courses-box mb-30">
                <div class="icon">
                    <i class='bx bxs-book-reader'></i>
                </div>
                <h3>B1</h3>
                <span>{{ "category.timeB1" | translate }}</span>
                <a routerLink="/single-courses3" class="link-btn"></a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-categories-courses-box mb-30">
                <div class="icon">
                    <i class='bx bx-laptop'></i>
                </div>
                <h3>B2 </h3>
                <span>{{ "category.timeB2" | translate }}</span>
                <a routerLink="/single-courses4" class="link-btn"></a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-categories-courses-box mb-30">
                <div class="icon">
                    <i class='bx bx-book'></i>
                </div>
                <h3>C1</h3>
                <span>{{ "category.timeB2" | translate }}</span>
                <a routerLink="/single-courses5" class="link-btn"></a>
            </div>
        </ng-template>
    </owl-carousel-o>
</div>
