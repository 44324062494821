<div class="become-instructor-partner-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="become-instructor-partner-content bg-color">
                    <h3>{{"Mission.who"  | translate}}</h3>
                    <p> {{"Mission.specialized"  | translate}} </p>
                    <a routerLink="/about-style-1" class="default-btn"><i class='bx bx-book-reader icon-arrow before'></i><span class="label">{{"Mission.more" | translate}} </span><i class="bx bx-book-reader icon-arrow after"></i></a>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="become-instructor-partner-image bg-image1">
                </div>
            </div>

        </div>
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="become-instructor-partner-image bg-image2">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="become-instructor-partner-content bg-color">
                    <h3>{{"Mission.who2"  | translate}}</h3>
                    <p>{{"Mission.who1"  | translate}}</p>
                    <a routerLink="/about-style-1" class="default-btn"><i class='bx bx-book-reader icon-arrow before'></i><span class="label">{{"Mission.more" | translate}} </span><i class="bx bx-book-reader icon-arrow after"></i></a>
                </div>

            </div>

        </div>
    </div>
</div>
