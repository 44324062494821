<div  class="container">

    <div class="mission-area ptb-100">
                <div class="container">
                    <div class="mission-content">
                        <div class="section-title text-start">
                            <span class="sub-title">{{"Mission.Welcom"  | translate}}</span>
                            <h2>Lessing Institut</h2>
                        </div>
                        <div class="mission-slides">
                            <owl-carousel-o [options]="missionSlides">
                                <ng-template carouselSlide>
                                    <h3>{{"Mission.who"  | translate}}</h3>
                                    <p> {{"Mission.specialized"  | translate}} </p>
                                    <a routerLink="/about-style-1" class="default-btn"><i class='bx bx-book-reader icon-arrow before'></i><span class="label">{{"Mission.more" | translate}} </span><i class="bx bx-book-reader icon-arrow after"></i></a>
                                </ng-template>
                                <ng-template carouselSlide>
                                    <h3>{{"Mission.who2"  | translate}}</h3>
                                    <p>{{"Mission.who1"  | translate}}</p>
                                    <a routerLink="/about-style-1" class="default-btn"><i class='bx bx-book-reader icon-arrow before'></i><span class="label">{{"Mission.more" | translate}} </span><i class="bx bx-book-reader icon-arrow after"></i></a>
                                </ng-template>
                            </owl-carousel-o>

                        </div>

                    </div>
                    <div class="row">

                        <div class="column2">


                        <div class="mission-slides">

                            <title >Video</title>
                            <video controls >
                                <source  src="assets\img\video1.mp4" type="video/mp4">
                            </video>
                        </div>
                        </div>
                        <div class="column">
                            <img src="assets/img/gallery22.png" />
                        </div>

                        </div>
                    </div>

            </div>






</div>











