import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';



import { HomePageTwoComponent } from './components/pages/home-page-two/home-page-two.component';
import { AboutPageOneComponent } from './components/pages/about-page-one/about-page-one.component';
import { EventsPageComponent } from './components/pages/events-page/events-page.component';
import { EventsDetailsPageComponent } from './components/pages/events-details-page/events-details-page.component';
import { GalleryPageComponent } from './components/pages/gallery-page/gallery-page.component';
import { FeedbackPageComponent } from './components/pages/feedback-page/feedback-page.component';
import { PartnerPageComponent } from './components/pages/partner-page/partner-page.component';
import { ComingSoonPageComponent } from './components/pages/coming-soon-page/coming-soon-page.component';
import { ContactPageComponent } from './components/pages/contact-page/contact-page.component';
import { CategoryPageOneComponent } from './components/pages/category-page-one/category-page-one.component';
import { CoursesListPageComponent } from './components/pages/courses-list-page/courses-list-page.component';
import { CoursesDetailsPageComponent } from './components/pages/courses-details-page/courses-details-page.component';
import { CoursesDetailsPageComponentDeux } from './components/pages/courses-details-page2/courses-details-page.component2';
import { CoursesDetailsPageComponentTROIS } from './components/pages/courses-details-page3/courses-details-page.component3';
import { CoursesDetailsPageComponentQUATRE } from './components/pages/courses-details-page4/courses-details-page.component4';
import { CoursesDetailsPageComponentFIVE } from './components/pages/courses-details-page5/courses-details-page.component5';
import { OrdersPageComponent } from './components/pages/orders-page/orders-page.component';
import { DownloadsPageComponent } from './components/pages/downloads-page/downloads-page.component';
import { EditAddressPageComponent } from './components/pages/edit-address-page/edit-address-page.component';
import { EditAccountPageComponent } from './components/pages/edit-account-page/edit-account-page.component';
import { EditBillingAddressPageComponent } from './components/pages/edit-billing-address-page/edit-billing-address-page.component';
import { EditShippingAddressPageComponent } from './components/pages/edit-shipping-address-page/edit-shipping-address-page.component';

const routes: Routes = [
    {path: 'course-portal-online', component: HomePageTwoComponent},
    {path: 'about-style-1', component: AboutPageOneComponent},
    {path: 'gallery', component: GalleryPageComponent},
    {path: 'events', component: EventsPageComponent},
    {path: 'single-events', component: EventsDetailsPageComponent},
    {path: 'feedback', component: FeedbackPageComponent},
    {path: 'partner', component: PartnerPageComponent},
    {path: 'coming-soon', component: ComingSoonPageComponent},
    {path: 'courses-category-style-1', component: CategoryPageOneComponent},
    {path: 'courses-list', component: CoursesListPageComponent},
    {path: 'single-courses', component: CoursesDetailsPageComponent},
    {path: 'single-courses2', component: CoursesDetailsPageComponentDeux},
    {path: 'single-courses3', component: CoursesDetailsPageComponentTROIS},
    {path: 'single-courses4', component: CoursesDetailsPageComponentQUATRE},
    {path: 'single-courses5', component: CoursesDetailsPageComponentFIVE},
    {path: 'orders', component: OrdersPageComponent},
    {path: 'downloads', component: DownloadsPageComponent},
    {path: 'edit-address', component: EditAddressPageComponent},
    {path: 'edit-account', component: EditAccountPageComponent},
    {path: 'edit-billing-address', component: EditBillingAddressPageComponent},
    {path: 'edit-shipping-address', component: EditShippingAddressPageComponent},
    {path: 'contact', component: ContactPageComponent},
    {
        path: '',
        redirectTo: 'course-portal-online',
        pathMatch: 'full'
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
